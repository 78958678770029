var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", { staticClass: "text-center" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "app-form",
        {
          attrs: {
            id: "login-form",
            submitAction: _vm.login,
            submitSuccessAction: () => this.$router.push("/"),
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "username",
                  placeholder: _vm.$t("account.login.username"),
                  type: "email"
                },
                model: {
                  value: _vm.username,
                  callback: function($$v) {
                    _vm.username = $$v
                  },
                  expression: "username"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "password",
                  placeholder: _vm.$t("account.login.password"),
                  type: "password"
                },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-checkbox", {
                attrs: {
                  id: "rememberMe",
                  label: _vm.$t("account.login.rememberMe")
                },
                model: {
                  value: _vm.rememberMe,
                  callback: function($$v) {
                    _vm.rememberMe = $$v
                  },
                  expression: "rememberMe"
                }
              })
            ],
            1
          ),
          _c("app-form-buttons", {
            staticClass: "login",
            attrs: {
              addSubmitButton: true,
              submitButtonText: "account.login.login"
            }
          })
        ],
        1
      ),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticStyle: { "padding-top": "10px" } }, [
      _c("a", { attrs: { href: "/account/reset-password-request" } }, [
        _vm._v("Hai dimenticato la password?")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }